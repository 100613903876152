$(document).ready(function(){
  // console.log('starting slideshow');
  $('.screenshots-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.screenshots-nav',
    lazyLoad: 'ondemand',
  });
  $('.screenshots-nav').slick({
    slidesToShow: 5,
    slidesToScroll: 3,
    asNavFor: '.screenshots-slider',
    dots: true,
    arrows: false,
    centerMode: true,
    focusOnSelect: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          // infinite: true,
          // dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          // infinite: true,
          dots: false,
        }
      },
    ],
  });

  $( '.swipebox' ).swipebox();
  
});